






























import {Vue, Component} from 'vue-property-decorator'

@Component({
    name: 'Imprint',
})
export default class Imprint extends Vue {}
