







import {Vue, Component} from 'vue-property-decorator'

import type {TopBarOptions} from '@/components/baseComponents/TopBar.vue'
import {URLS} from '@/const'

@Component({
    name: 'Privacy',
})
export default class Privacy extends Vue {
    url = URLS.PRIVACY

    get buttons(): TopBarOptions['buttons'] {
        return !this.$dbiStore.getters.isLoggedIn
            ? {
                left: {
                    icon: 'arrow-left',
                    title: 'zurück',
                    onClick: () => {
                        this.$router.back()
                    },
                },
                right: false,
            }
            : {}
    }
}
