






import {Component, Vue} from 'vue-property-decorator'

@Component({
    name: 'Error',
})
export default class StationDetails extends Vue {}
